import { Link } from "react-router-dom";
import styles from "./Post.module.css";
import classNames from "classnames/bind";

import { useState } from "react";
// import { useInView } from "react-intersection-observer";
const cx = classNames.bind(styles);

export const Product = ({ product }) => {
  const [showList, setShowList] = useState(false);
  // const { ref, inView } = useInView({
  //   // inView를 통해 보여지는지 구분한다.
  //   // 각종 option을 추가해서 IntersectionObserver처럼 사용할 수 있다.
  //   threshold: 0,
  //   triggerOnce: true,
  // });
  let str = product.tag;
  let tagBigColumn = false;
  let tagBigBigColumn = false;
  let tagSmallColumn = false;
  let tagSmallSmallColumn = false;

  // setRes(product.tag.replace(/ /g, ""));
  product.tag.map((tagName) => {
    if (tagName == "Development") {
      return (tagBigColumn = true);
    } else if (tagName == "Identity and Branding") {
      return (tagBigBigColumn = true);
    } else if (tagName == "Book") {
      return (tagSmallColumn = true);
    } else if (tagName == "Booklet") {
      return (tagSmallSmallColumn = true);
    }
  });
  return (
    <div
      className={cx(
        "width100",
        { BigColumn: tagBigColumn },
        { SmallColumn: tagSmallColumn },
        { SmallSmallColumn: tagSmallSmallColumn }
      )}
    >
      <div className={styles.product}>
        <Link to={`post/${product.id}`}>
          <div className={styles.product_image}>
            <img src={product.thumbnail.meta.download_url} alt="product" />
          </div>
        </Link>{" "}
      </div>
    </div>
  );
};
