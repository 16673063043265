import React from "react";
import styles from "./main.module.css";
import { Product } from "../post/Post";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import axios from "axios";
import { PostPages } from "../api/Api";

const cx = classNames.bind(styles);

export const Main = ({
  products,
  setProducts,
  columns,
  filterTags,
  setFilterTags,
  height,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    PostPages(products, setProducts, loading, setLoading);
    setFilterTags("");
  }, []);

  // products.sort((a, b) => {
  //   let dateA = new Date(a.date),
  //     dateB = new Date(b.date);
  //   return dateB - dateA;
  // });
  // console.log(products);

  useEffect(() => {}, [products]);

  const filteredDATA = products.filter((node) =>
    filterTags.length > 0
      ? filterTags.some((filterTag) =>
          node.tag.map((tag) => tag).includes(filterTag)
        )
      : products
  );

  // console.log(products);
  // console.log(productsList);
  //   let heightVar = `calc(50px + ${height}px)`;

  return (
    <>
      <div className={styles.logo} style={{ top: height }}>
        {" "}
        <img
          src={`${process.env.PUBLIC_URL}/static/images/relay_logo.svg`}
          className={styles.Applogo}
          alt="React"
        />
      </div>
      <main
        className={cx(
          "grid_wrap",
          { column1: columns == 1 },
          { column2: columns == 2 },
          { column3: columns == 3 },
          { column4: columns == 4 },
          { column5: columns == 5 }
        )}
      >
        {filteredDATA.map((product) => {
          return <Product key={`key-${product.id}`} product={product} />;
        })}
      </main>
      <footer>Copyright © 2023 relay all rights reserved.</footer>
    </>
  );
};
