import styles from "./topNavigationBar.module.css";
import { Link } from "react-router-dom";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import Checkbox from "../../util/Checkbox";
import { MoreInfo } from "../../moreinfo/MoreInfo";
import classNames from "classnames";
import { TagList } from "../../api/Api";

const cx = classNames.bind(styles);

export const TopNavigationBar = ({
  filterTags,
  setFilterTags,
  currentTag,
  setCurrentTag,
  filterStatus,
  height,
  setHeight,
}) => {
  const ref = useRef(null);

  const [tags, tagsChange] = useState([]);
  const [ismenuClicked, setIsmenuClicked] = useState(false);
  const [menuText, setmenuText] = useState("(i)");

  // console.log(filterTags);

  // console.log(filterStatus);

  const checkCheck = (tag) => {
    let ornot = 0;
    if (filterTags.length > 0) {
      // return filterTags.map((nodes) => {
      return filterTags.includes(tag);
    } else return false;
  };
  const filterHandler = (event) => {
    if (event.target.checked) {
      setFilterTags([...filterTags, event.target.value]);
    } else {
      setFilterTags(
        filterTags.filter((filterTag) => filterTag !== event.target.value)
      );
    }
  };
  useEffect(() => {
    TagList(tags, tagsChange);
  }, []);
  useEffect(() => {
    setHeight(ref.current.clientHeight);
  });

  return (
    <>
      <MoreInfo
        ismenuClicked={ismenuClicked}
        setIsmenuClicked={setIsmenuClicked}
        height={height}
      />
      <header className={styles.header} ref={ref}>
        <div className={styles.inner}>
          {tags.map((tag, index) => {
            // console.log(tag + "  " + index + "  " + checkCheck(tag));
            return (
              <Checkbox
                key={`tag-${index}`}
                text={tag}
                disabled={filterStatus}
                value={tag}
                filterHandler={filterHandler}
                check={checkCheck(tag)}
              />
            );
          })}
        </div>

        <div className={styles.menu}>
          <div
            className={styles.moreInfoBtn}
            onClick={() => {
              if (ismenuClicked == true) {
                setIsmenuClicked(false);
                setmenuText("(i)");
              } else {
                setIsmenuClicked(true);
                setmenuText("×");
              }
            }}
          >
            <span>{menuText}</span>
          </div>
        </div>
      </header>
    </>
  );
};
