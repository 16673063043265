import { Detail } from "../components/product_detail/detail";
import { useEffect } from "react";

const Product = (prop) => {
  // console.log(prop.productsList);
  useEffect(() => {
    prop.setFilterStatus(true);
  }, []);
  console.log(prop.height);
  return (
    <Detail
      filterTags={prop.filterTags}
      setFilterTags={prop.setFilterTags}
      filterStatus={prop.filterStatus}
      height={prop.height}
    />
  );
};

export default Product;
