import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  font-family: ABCWhyte;
  letter-spacing: -0.0em;
  position: relative;
  top: -0.0em;
  }
  label[disabled] {
opacity:0.4}
  .ml-ko{
      font-family: "sandoll-gothicneo2", sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
  font-style: normal;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  }
.ml-en,
.ml-num {
  font-family: ABCWhyte;
  letter-spacing: -0.02em;
  position: relative;
  top: -0.05em;
}
  body {
    line-height: 1.5;
    height:100%;
    width:100%;
    overflow:hidden;
  }
    .screen{
    height: calc(100vh - 50px);
    overflow:scroll;
    overflow-x:hidden;
     -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .screen::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
  @media (max-width: 720px) {

  .screen{
    height: calc(100svh - 110px);
    overflow:scroll;
  }
}
`;

export default GlobalStyle;
