const post_list_url =
  "/api/v2/pages/?type=post.Postpage&order=-date&fields=title,thumbnail,body,tag,desc,engDesc,keytag,date&limit=200";
const tag_url = "/api_rest/tags";
const post_url = "/api/v2/pages/";
const rel_url = "/api/v2/pages/?type=post.postpage&fields=tag,keytag&keytag=";

const PostPages = async (products, setProducts, loading, setLoading) => {
  return fetch(post_list_url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // alert("eeeeee");
      setProducts(data.items);
      // setLoading(true);
    })
    .catch((error) => console.log(error));
};

function TagList(tags, tagsChange) {
  return fetch(tag_url)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // console.log(data);
      tagsChange(data);
    })
    .catch((error) => console.log(error));
}

const Post = async (
  id,
  product,
  setProduct,
  setLoading,
  productsList,
  setProductsList
) => {
  Promise.all([
    fetch(post_list_url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProductsList([]);
        for (let i in data.items) {
          setProductsList((productsList) => [
            ...productsList,
            { id: data.items[i].id, title: data.items[i].title },
          ]);
        }
      }),
    fetch(post_url + id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setProduct(data);
        setLoading(false);
        // console.log(data);
      }),
  ]).then();
};
function PrevPost(id, prevProduct, setPrevProduct) {
  let prevId = parseInt(id) - 1;
  if (prevId == 3) return null;
  else {
    return fetch(post_url + prevId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPrevProduct(data);
      })
      .catch((error) => console.log(error));
  }
}
function NextPost(id, nextProduct, setNextProduct) {
  let nextId = parseInt(id) + 1;
  return fetch(post_url + nextId)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      // console.log(data);
      setNextProduct(data);
    })
    .catch((error) => console.log(error));
}

export { PostPages, TagList, Post, NextPost, PrevPost };
