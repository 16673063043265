import "./App.css";
import GlobalStyle from "./Globalstyle";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Transition from "./Transition";

import { useState } from "react";

function App() {
  var MultiLingual = require("multilingual.js");
  var ml = new MultiLingual({
    containers: document.getElementsByClassName("screen"), // 배열 형태를 띈 dom 요소를 불러옵니다. $(".content") 와 같은 형태
    configuration: ["en", "num", "ko"],
  });

  return (
    <Router>
      <GlobalStyle />
      <Transition />
    </Router>
  );
}

export default App;
