import styles from "./setColumn.css";
import classNames from "classnames";
import { Product } from "../post/Post";
import { useEffect, useState } from "react";

const cx = classNames.bind(styles);

const SetColumn = ({ columns, setColumns, height }) => {
  const handleColumns = (type) => {
    if (type === "plus") {
      if (columns == 5) return;
      setColumns(columns + 1);
    } else {
      if (columns === 1) return;
      setColumns(columns - 1);
    }
  };

  let heightVar = `calc(50px + ${height}px)`;
  console.log(heightVar);
  return (
    <div className={cx("amount")} style={{ top: heightVar }}>
      <span
        className={cx("minus")}
        src="/images/icon-minus-line.svg"
        alt="minus"
        onClick={() => handleColumns("minus")}
      >
        +
      </span>

      <div className={styles.count}></div>

      <span
        className={cx("plus")}
        src="/images/icon-plus-line.svg"
        alt="plus"
        onClick={() => handleColumns("plus")}
      >
        -
      </span>
    </div>
  );
};

export default SetColumn;
