import styles from "./DetailNavi.css";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Product from "../../pages/product";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";

const cx = classNames.bind(styles);

const DetailNavi = ({ NextProduct, PrevProduct }) => {
  const location = useLocation();

  return (
    <div className="detailNav">
      <Link to={`../post/${PrevProduct.id}`}>
        <span
          className={cx("arrowPrev", "naviArrow", { hidden: !PrevProduct })}
        >
          ← Prev
        </span>
      </Link>
      <Link to={`../post/${NextProduct.id}`}>
        <span
          className={cx("arrowNext", "naviArrow", { hidden: !NextProduct })}
        >
          Next →
        </span>
      </Link>
    </div>
  );
};

export default DetailNavi;
