import React from "react";
import { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { createRef } from "react";
import Home from "./pages/home";
import Product from "./pages/product";
import { TopNavigationBar } from "./components/header/topNavigationBar/topNavigationBar";
import "./Transition.css";
import { Spinner } from "./components/Spinner";

function Transition() {
  const [products, setProducts] = useState([]);
  const [height, setHeight] = useState(0);
  const [currentTag, setCurrentTag] = useState([]);
  const [columns, setColumns] = useState(2);
  const [filterTags, setFilterTags] = useState([]);

  const [filterStatus, setFilterStatus] = useState(false);

  const location = useLocation();
  let [show, setShow] = useState(false);
  return (
    <TransitionGroup className="transition-group">
      <TopNavigationBar
        filterTags={filterTags}
        setFilterTags={setFilterTags}
        currentTag={currentTag}
        setCurrentTag={setCurrentTag}
        filterStatus={filterStatus}
        height={height}
        setHeight={setHeight}
      />
      <CSSTransition
        in={show}
        key={location.pathname}
        classNames="fade"
        timeout={500}
      >
        {/* <Suspense fallback={<Spinner />}> */}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Home
                products={products}
                setProducts={setProducts}
                columns={columns}
                setColumns={setColumns}
                filterTags={filterTags}
                setFilterTags={setFilterTags}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                height={height}
              />
            }
          />
          <Route
            path="/post/:id/*"
            exact
            key={window.location.pathname}
            element={
              <Product
                key={window.location.pathname}
                filterTags={filterTags}
                setFilterTags={setFilterTags}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                height={height}
                // productsList={productsList}
              />
            }
          />
          {/* {console.log(window.location.pathname)}; */}
        </Routes>
        {/* </Suspense> */}
      </CSSTransition>
    </TransitionGroup>
  );
}
export default Transition;
