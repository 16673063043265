import { Main } from "../components/main/main";
import { useState, useEffect } from "react";
import SetColumn from "../components/header_ui/SetColumn";

const Home = ({
  products,
  setProducts,
  columns,
  setColumns,
  filterTags,
  setFilterTags,
  filterStatus,
  setFilterStatus,
  height,
}) => {
  useEffect(() => {
    setFilterStatus(false);
  }, []);
  let heightVar = `calc(100svh - ${height}px)`;
  return (
    <div className="screen" style={{ height: heightVar, top: height }}>
      <Main
        products={products}
        setProducts={setProducts}
        columns={columns}
        filterTags={filterTags}
        setFilterTags={setFilterTags}
        height={height}
      />{" "}
      <SetColumn columns={columns} setColumns={setColumns} height={height} />
    </div>
  );
};

export default Home;
