import styles from "./Related.module.css";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Product from "../../pages/product";
const cx = classNames.bind(styles);

const Related = ({ id, product }) => {
  let tagBigColumn = false;
  let tagBigBigColumn = false;
  let tagSmallColumn = false;
  product.tag.map((tagName) => {
    if (tagName == "Development") {
      return (tagBigColumn = true);
    } else if (tagName == "Identity and Branding") {
      return (tagBigBigColumn = true);
    } else if (tagName == "Book") {
      return (tagSmallColumn = true);
    }
  });
  if (id == product.id) {
    return null;
  } else {
    return (
      <div
        className={cx(
          "productImage",
          { BigColumn: tagBigColumn },
          { SmallColumn: tagSmallColumn }
        )}
      >
        <Link to={`../post/${product.id}`}>
          <img src={product.thumbnail.meta.download_url} alt="product" />
        </Link>
      </div>
    );
  }
};

export default Related;
