import React from "react";
import styled from "styled-components";

function Checkbox({ text, value, filterHandler, check, disabled }) {
  // console.log(typeof check);
  check = JSON.parse(check);
  // console.log(typeof check);
  // console.log(disabled);
  return (
    <div>
      <StyledInput
        type="checkbox"
        name={text}
        id={text}
        value={value}
        onChange={filterHandler}
        checked={check}
        disabled={disabled}
      />
      <StyledLabel htmlFor={text} disabled={disabled}>
        <StyledP>{text}</StyledP>
      </StyledLabel>
    </div>
  );
}

export default Checkbox;

const StyledLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
  z-index: 1;
  &:before {
    display: block;
    content: "";
    height: 16px;
    width: 16px;
    background-color: var(--backgroundColor);
    border: 1px solid white;
    border-radius: 16px;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    opacity: 0;
    content: "";
    display: block;
    content: "";
    height: 16px;
    width: 16px;
    background-color: var(--backgroundColor);
    border: 1px solid white;
    border-radius: 16px;
    background-image: url("../static/images/circle.svg");
    background-size: 80% 80%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
`;

// visually-hidden
// https://www.a11yproject.com/posts/how-to-hide-content/
const StyledInput = styled.input`
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  width: 1px;

  &:checked + ${StyledLabel} {
    :after {
      opacity: 1;
    }
  }
`;

const StyledP = styled.p`
  margin-left: 0.5rem;
  font-size: 15px;
`;
