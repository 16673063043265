import styles from "./moreInfo.css";
import classNames from "classnames";
import { useState } from "react";
const cx = classNames.bind(styles);

export const MoreInfo = (ismenuClicked) => {
  // console.log(ismenuClicked);
  let heightWmargin = ismenuClicked.height + 50;

  let heightVar = `calc(100svh - ${heightWmargin}px)`;
  let menuClicked = ismenuClicked.ismenuClicked;
  return (
    <div
      className={cx("moreInfowrap", {
        opened: ismenuClicked.ismenuClicked,
      })}
      style={
        menuClicked
          ? { maxHeight: heightVar, top: ismenuClicked.height }
          : { top: ismenuClicked.height }
      }
    >
      <p className={styles.moreInfoKr}>
        릴레이 <br />
        릴레이는 김재환, 김형도로 구성된 그래픽디자인 스튜디오다. 브랜딩,
        웹디자인 및 개발, 전시, 출판물 등 그래픽디자인의 전반적인 작업을
        수행하며, 국내 여러 문화기관 및 독립 큐레이터, 동시대 작가들과 활발하게
        협업하고 있다.
      </p>
      <p className={styles.moreInfoEng}>
        relay <br />
        relay is a graphic design studio founded by Kim Jaehwan and Kim Hyungdo.
        The design spectrum of relay varies from Branding, Web design and
        development, Exhibition, and Publication to collaboration with
        nationwide cultural institutes and independent curators and artists.
      </p>
      <p className={styles.moreInfoEng}>
        <a onClick={() => (window.location = "mailto:studio@relay-works.kr")}>
          studio@relay-works.kr
        </a>
        <br />
        instagram
        <a
          onClick={() =>
            window.open("https://www.instagram.com/relay.works/", "_blank")
          }
        >
          @relay.works
        </a>
        <br />{" "}
        <a onClick={() => (window.location = "tel:82222850403")}>
          +82 2-2285-0403
        </a>
      </p>
      <p className={styles.moreInfoKr}>
        서울특별시 중구 충무로4길 6. 초동빌딩 4층 403호 <br />
        403, 4F 6, Chungmu-ro 4-gil, Jung-gu, Seoul
      </p>
    </div>
  );
};
