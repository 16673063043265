import styles from "./detail.module.css";
import { useParams } from "react-router-dom";
import Related from "../post/Related";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DetailNavi from "../header_ui/DetailNavi";
import { Post, NextPost, PrevPost, PostPages } from "../api/Api";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { TopNavigationBar } from "../header/topNavigationBar/topNavigationBar";

export const Detail = (filterTags, height) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(null);
  const [product, setProduct] = useState({});
  const [prevProduct, setPrevProduct] = useState({});
  const [nextProduct, setNextProduct] = useState({});
  const [relProducts, setRelProducts] = useState({});
  const [productsList, setProductsList] = useState({});
  let relatedData = [];
  const contValue = (contValue) => {
    return contValue.map((img, key) => <img src={img.download_url} />);
  };
  const contTxtValue = (contValue) => {
    return contValue.map((txt, key) => {
      return <div key={key} dangerouslySetInnerHTML={{ __html: txt }}></div>;
    });
  };
  const contVideoValue = (contValue) => {
    let padding = (contValue.height / contValue.width) * 100;
    let paddingCalc = padding + "%";
    return (
      <div className={styles.Embed} style={{ paddingBottom: paddingCalc }}>
        <iframe
          width={contValue.width}
          height={contValue.height}
          src={contValue.url}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    );
  };
  useEffect(() => {
    if (product.tag != undefined && filterTags.filterTags.length == 0) {
      // console.log("in");
      // console.log(product.tag);
      filterTags.setFilterTags(
        product.tag.map((currentTag) => {
          return currentTag;
        })
      );
      console.log(filterTags.height);
    }
  }, [product]);
  // console.log(product);
  useEffect(() => {
    if (!(Object.keys(productsList).length === 0)) {
      // console.log(productsList);
      const idx = productsList.forEach((nodes, idx) => {
        if (nodes.id == id) {
          // console.log(idx);
          if (idx == 0) {
            setPrevProduct(false);
            // console.log(idx + 1);
            // console.log(productsList[idx + 1]);
            if (productsList[idx + 1] !== undefined) {
              setNextProduct(productsList[idx + 1]);
            }
          } else {
            setPrevProduct(productsList[idx - 1]);
            // console.log(idx + 1);
            // console.log(productsList[idx + 1]);
            if (productsList[idx + 1] !== undefined) {
              setNextProduct(productsList[idx + 1]);
            }
          }
        }
      });
    }
  }, [productsList]);
  useEffect(() => {
    setLoading(true);
    PostPages(relProducts, setRelProducts);
    Post(id, product, setProduct, setLoading, productsList, setProductsList);

    filterTags.setFilterTags([]);
    if (Object.keys(nextProduct).length === 0 || nextProduct.message != null) {
      setNextProduct(false);
    }
    if (Object.keys(prevProduct).length === 0 || prevProduct.message != null) {
      setPrevProduct(false);
    }
  }, []);
  if (
    !(
      relProducts === undefined ||
      Object.keys(relProducts).length === 0 ||
      product.keytag == undefined
    )
  ) {
    let filterKeyTags = product.keytag;
    relatedData = relProducts.filter((node) =>
      filterKeyTags.some((filterTag) =>
        node.keytag.map((tag) => tag).includes(filterTag)
      )
    );
  }
  let heightVar = `calc(100svh - ${filterTags.height}px)`;

  return (
    <div
      className="screen content"
      style={{ height: heightVar, top: filterTags.height }}
    >
      <Link to="/">
        <div className={styles.logo} style={{ top: filterTags.height }}>
          <img
            src={`${process.env.PUBLIC_URL}/static/images/home_btn.svg`}
            className={styles.Applogo}
            alt="React"
          />
          <span className={styles.logoArrow}>←</span>
        </div>
      </Link>
      {loading ? (
        <div></div>
      ) : (
        <>
          <main className={styles.main}>
            <section className={styles.post}>
              <div className={styles.post_info}>
                <p className={styles.post_name}>{product.head}</p>{" "}
                <span
                  className={styles.post_descEng}
                  dangerouslySetInnerHTML={{ __html: product.engDesc }}
                ></span>
                <span
                  className={styles.post_desc}
                  dangerouslySetInnerHTML={{ __html: product.desc }}
                ></span>
              </div>
            </section>

            <section className={styles.post}>
              {/* <div className={styles.post_imgthumb}>
                <img
                  src={product.thumbnail && product.thumbnail.meta.download_url}
                  // alt="product"
                />
              </div> */}
              {product.body &&
                product.body.map((cont, idx) => {
                  // const txts = contValue(cont.value);

                  if (cont.type == "OneImage")
                    return (
                      <div key={idx} className={styles.OneImg}>
                        {contValue(cont.value)}
                      </div>
                    );
                  if (cont.type == "TwoImage") {
                    return (
                      <div key={idx} className={styles.TwoImg}>
                        {contValue(cont.value)}
                      </div>
                    );
                  }
                  if (cont.type == "ThreeImage") {
                    return (
                      <div key={idx} className={styles.ThreeImg}>
                        {contValue(cont.value)}
                      </div>
                    );
                  }
                  if (cont.type == "OneColumnText") {
                    return (
                      <div key={idx} className={styles.OneColumnText}>
                        {contTxtValue(cont.value)}
                      </div>
                    );
                  }
                  if (cont.type == "TwoColumnText") {
                    return (
                      <div key={idx} className={styles.TwoColumnText}>
                        {contTxtValue(cont.value)}
                      </div>
                    );
                  }
                  if (cont.type == "ThreeColumnText") {
                    return (
                      <div key={idx} className={styles.ThreeColumnText}>
                        {contTxtValue(cont.value)}
                      </div>
                    );
                  }
                  if (cont.type == "Embed") {
                    return (
                      <div key={idx} className={styles.Container}>
                        {contVideoValue(cont.value)}
                      </div>
                    );
                  }
                })}
            </section>
            <div className={styles.relatedWrap}>
              {relatedData != undefined && relatedData.length > 1 && (
                <div className={styles.relatedTitle}>related post</div>
              )}
              {relatedData != undefined &&
                relatedData.length > 1 &&
                relatedData.map((product) => {
                  return (
                    <Related
                      key={`key-${product.id}`}
                      id={id}
                      product={product}
                    />
                  );
                })}
            </div>

            <DetailNavi NextProduct={nextProduct} PrevProduct={prevProduct} />
          </main>
        </>
      )}
    </div>
  );
};
